
export const MOLECULES_TILE_LAYOUTS = ['vertical', 'horizontal', 'small-tile']
export const RATE_RISE_NUDGEBAR_ALLOWED_PATHS = [
  '/health-insurance',
  '/health-insurance/rate-rise-calculator',
  '/health-insurance/health-insurance-premium-increase/',
  '/health-insurance/quote/form/step1',
  '/health-insurance/quotes-results',
  '/health-insurance/quote/resume'
]
